import React, { useContext } from 'react';
import { func, number, string, bool } from 'prop-types';
import { useFormState } from 'react-hook-form';
import { Button } from '@one-thd/sui-atomic-components';
import { CalculatorContext } from '../../CalculatorContext';
import { clickAddAreaAnalytics } from '../../../../analytics';

export const AddAreaButton = ({ onAddAreaClick, selectedArea, variant, disabled }) => {
  const {
    addElement,
    calculate: {
      coverage
    },
    control,
    onSubmit,
    fields
  } = useContext(CalculatorContext);
  const {
    errors: { entries: areaErrors = [] },
  } = useFormState({ control });

  const hasDefaultAreaOnly = selectedArea === null && !coverage;
  const isError = selectedArea === null ? false : areaErrors[selectedArea] !== undefined;

  return (
    <Button
      fullWidth
      onClick={async () => {
        const success = await onSubmit();
        if (success) {
          const currentAreaName = fields[selectedArea]?.name;
          addElement({ calcByArea: fields[selectedArea]?.calcByArea || false });
          clickAddAreaAnalytics();
          onAddAreaClick(!hasDefaultAreaOnly && isError, fields.length, currentAreaName);
        }
      }}
      variant={variant}
      disabled={disabled}
    >
      {
        selectedArea !== null
          ? 'Save and Add Another Area'
          : 'Add an Area'
      }
    </Button>
  );
};

AddAreaButton.displayName = 'AddAreaButton';

AddAreaButton.propTypes = {
  onAddAreaClick: func.isRequired,
  selectedArea: number,
  variant: string.isRequired,
  disabled: bool,
};

AddAreaButton.defaultProps = {
  selectedArea: null,
  disabled: false
};