import React, { useContext, useState, useRef, useEffect } from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import classNames from 'classnames';
import { number } from 'prop-types';

import { IconButton, Typography } from '@one-thd/sui-atomic-components';
import { Cancel, Checkmark, Edit } from '@one-thd/sui-icons';
import { CalculateByDimensions } from '../../../CalculateByDimensions';
import { CalculatorContext } from '../../CalculatorContext';
import { CalculatorInputElement } from '../../CalculatorInputElement';
import { CalculatorInputSections } from '../../../CalculatorInputSections';
import { DEFAULT_CALC_CLASSES } from '../../../../constants';

export const SingleAreaCalculatorBody = ({ selectedArea }) => {
  const {
    control,
    addElement
  } = useContext(CalculatorContext);
  const entries = useWatch({
    control,
    name: 'entries'
  });
  const [editActive, setEditActive] = useState(false);
  const prevNameRef = useRef(entries?.[selectedArea]?.name);

  useEffect(() => {
    if (selectedArea === 0 && entries.length < 1) {
      addElement({ calcByArea: false });
    }
  }, [selectedArea]);

  const cancelClick = () => {
    const newAreas = [...entries];
    newAreas[selectedArea].name = prevNameRef.current;
    setEditActive(false);
  };

  const editClick = () => {
    prevNameRef.current = entries[selectedArea].name;
    setEditActive(!editActive);
  };

  const dimensionNames = ['Length', 'Width'];

  return (
    <div className={`${DEFAULT_CALC_CLASSES} sui-w-full`}>
      {selectedArea !== null && !editActive && (
        <div className="sui-grid sui-grid-cols-12 sui-items-center">
          <span className="sui-pr-3 sui-col-span-11">
            <Typography truncate variant="h3">{entries[selectedArea]?.name}</Typography>
          </span>
          <IconButton icon={Edit} aria-label="EditIcon" onClick={() => editClick()} />
        </div>
      )}
      {selectedArea !== null && editActive && (
        <div className="sui-flex sui-items-center sui-mb-3 sui-w-full">
          <CalculatorInputElement
            index={selectedArea}
            propName="name"
            hideLabel
            endAdornment={(
              <IconButton
                aria-label="CancelIcon"
                icon={Cancel}
                onClick={() => cancelClick()}
              />
            )}
          />
          <div className="sui-mt-2">
            <IconButton
              aria-label="CheckMarkIcon"
              icon={Checkmark}
              onClick={() => setEditActive(!editActive)}
            />
          </div>
        </div>
      )}
      <CalculateByDimensions dimensionNames={dimensionNames} selectedArea={selectedArea} />
      <CalculatorInputSections dimensionNames={dimensionNames} selectedArea={selectedArea} hideAreaText />
    </div>
  );
};

SingleAreaCalculatorBody.displayName = 'SingleAreaCalculatorBody';

SingleAreaCalculatorBody.propTypes = {
  selectedArea: number
};

SingleAreaCalculatorBody.defaultProps = {
  selectedArea: null
};