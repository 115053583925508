import React, { useContext, useEffect, useRef } from 'react';
import { func, number } from 'prop-types';
import { useFormState, useWatch } from 'react-hook-form';
import { Button } from '@one-thd/sui-atomic-components';
import { CalculatorContext } from '../../CalculatorContext';
import { AddAreaButton } from '../sharedButtons/AddAreaButton';
import { saveAreaAnalytics } from '../../../../analytics';

export const SingleAreaCalculatorFooter = ({ onAddAreaClick, onSave, selectedArea }) => {
  const {
    addSavedAreaToaster,
    addUnsavedChangesToaster,
    control,
    fields,
    lineItemName,
    onSubmit,
    updateElement,
    removeInputElement
  } = useContext(CalculatorContext);
  const {
    errors: { entries: areaErrors = [] },
  } = useFormState({ control });
  const entries = useWatch({
    control,
    name: 'entries'
  });

  const isErr = areaErrors[selectedArea] !== undefined;
  const areaNameEdited = fields[selectedArea]?.name !== `${lineItemName} ${selectedArea + 1}`;
  const calcByArea = fields[selectedArea]?.calcByArea;

  // Assigning the error to ref to keep the error value on unmount
  const originalValueRef = useRef();
  const saveSuccessRef = useRef(false);

  useEffect(() => {
    if (entries[selectedArea]) {
      originalValueRef.current = {
        ...entries[selectedArea]
      };
    }
    saveSuccessRef.current = false;
  }, [selectedArea]);

  useEffect(() => {
    // When unmounting on footer (which is hitting back or close on the drawer) and there is an error,
    // trigger an unsaved toaster message
    return async () => {
      if (saveSuccessRef.current) {
        return;
      }

      addUnsavedChangesToaster({ selectedArea });

      if (originalValueRef.current) {
        const { length, width, sqFootage } = originalValueRef.current;

        if ((length && width) || sqFootage) {
          updateElement(selectedArea, originalValueRef.current);
        } else {
          removeInputElement(selectedArea);
        }
      }
    };
  }, [selectedArea]);

  const saveAndFinishArea = async () => {
    const success = await onSubmit();
    if (success) {
      addSavedAreaToaster({ selectedArea });
      saveAreaAnalytics({ areaNameEdited, calcByArea, isAddingNewArea: true });
      saveSuccessRef.current = true;
      onSave();
    }
  };

  const onAddAreaClickWrapper = async (isError, areaLength, currentAreaName) => {
    addSavedAreaToaster({ selectedArea });
    saveAreaAnalytics({ areaNameEdited, calcByArea, isAddingNewArea: true });
    saveSuccessRef.current = true;
    onAddAreaClick(isError, areaLength, currentAreaName);
  };

  return (
    <>
      <Button
        fullWidth
        variant="primary"
        onClick={saveAndFinishArea}
        disabled={isErr}
      >
        Save and Finish This Area
      </Button>
      <AddAreaButton
        onAddAreaClick={onAddAreaClickWrapper}
        selectedArea={selectedArea}
        variant="secondary"
        disabled={isErr}
      />
    </>
  );
};

SingleAreaCalculatorFooter.displayName = 'SingleAreaCalculatorFooter';

SingleAreaCalculatorFooter.propTypes = {
  onAddAreaClick: func.isRequired,
  onSave: func.isRequired,
  selectedArea: number
};

SingleAreaCalculatorFooter.defaultProps = {
  selectedArea: null
};