import React, { useContext } from 'react';
import { func, number } from 'prop-types';

import { Button } from '@one-thd/sui-atomic-components';
import { CalculatorContext } from '../../CalculatorContext';

export const DeleteConfirmationFooter = ({ onCancel, onConfirm, selectedArea }) => {
  const {
    addRemovedAreaToaster,
    removeInputElement,
    fields
  } = useContext(CalculatorContext);

  const deleteElement = () => {
    addRemovedAreaToaster({ selectedArea });
    removeInputElement(selectedArea);

    let closeDrawer = false;
    if ((fields.length === 1 && selectedArea === 0) || selectedArea === null) {
      closeDrawer = true;
    }

    onConfirm(closeDrawer);
  };

  return (
    <>
      <Button
        onClick={deleteElement}
        fullWidth
        variant="primary"
      >
        Yes, Delete {selectedArea !== null ? 'Area' : 'All'}
      </Button>
      <Button
        onClick={onCancel}
        fullWidth
        variant="secondary"
      >
        No Thanks
      </Button>
    </>
  );
};

DeleteConfirmationFooter.displayName = 'DeleteConfirmationFooter';

DeleteConfirmationFooter.propTypes = {
  onCancel: func.isRequired,
  onConfirm: func.isRequired,
  selectedArea: number
};

DeleteConfirmationFooter.defaultProps = {
  selectedArea: null
};
