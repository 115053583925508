import React, { useContext, useEffect, useRef } from 'react';
import { bool, node, string } from 'prop-types';
import { Alert } from '@one-thd/sui-atomic-components';
import { ToasterContext } from './ToasterProvider';

export const Toaster = React.memo(({
  action, children, showCloseButton, timeoutKey, status
}) => {
  const mountedRef = useRef(false);
  const { removeToaster } = useContext(ToasterContext);

  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
    }
    setTimeout(() => {
      if (mountedRef.current) {
        removeToaster({ key: timeoutKey });
      }
    }, 8000);

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <Alert
      action={action}
      onClose={showCloseButton ? () => removeToaster({ key: timeoutKey }) : undefined}
      status={status}
    >
      {children}
    </Alert>
  );
});

Toaster.displayName = 'Toaster';

Toaster.propTypes = {
  action: node,
  children: node.isRequired,
  showCloseButton: bool,
  timeoutKey: string.isRequired,
  status: string.isRequired
};

Toaster.defaultProps = {
  action: null,
  showCloseButton: false
};