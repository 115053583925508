import React, { useRef, useState, useEffect, useContext } from 'react';
import { bool, func, string } from 'prop-types';
import { useSessionStorage } from '@thd-olt-global/thd-storage-utils';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader
} from '@one-thd/sui-atomic-components';
import {
  DeleteConfirmationBody, DeleteConfirmationFooter,
  RoomAreasBody, RoomAreasFooter,
  SingleAreaCalculatorBody, SingleAreaCalculatorFooter,
  ToasterFooter,
  CalculatorContext
} from '@thd-olt-component-react/calculator';

import { overlayAnalytics } from '../../analytics';
import {
  ADD_AREA,
  DELETE_ALL_INPUTS_CONFIRMATION,
  PLAN_YOUR_PROJECT,
  LANDING
} from '../../constants';

const CalculatorDrawer = ({ drawerOpen, setDrawerOpen, label }) => {
  const { calculate, fields } = useContext(CalculatorContext);
  const [toggled, setToggled] = useSessionStorage('flooringPicker_projectPrice');
  const { coverage } = calculate;
  const lastSelectedAreaNameRef = useRef('');
  const [menuName, setMenuName] = useState(
    (fields && (fields.length === 0 || coverage === 0)) ? ADD_AREA : LANDING
  );
  const [selectedArea, setSelectedArea] = useState((fields.length === 0 || coverage === 0) ? 0 : null);
  const isLanding = menuName === LANDING;

  useEffect(() => {
    overlayAnalytics();
  }, []);

  useEffect(() => {
    if (isLanding) {
      setSelectedArea(null);
    }
  }, [menuName]);

  useEffect(() => {
    if (coverage === 0 && toggled) {
      setToggled(false);
    }
  }, [coverage, toggled]);

  const reset = (closeDrawer = false) => {
    setMenuName(fields.length ? LANDING : ADD_AREA);
    setSelectedArea(fields.length ? 0 : null);

    if (closeDrawer) {
      setToggled(false);
      setDrawerOpen(false);
    }
  };

  // Drawer functions
  const onBack = !isLanding && fields.length ? () => reset() : undefined;

  const onClose = () => {
    reset();
    setDrawerOpen(false);
  };

  // Body/Footer functions
  const onAddAreaClick = (isError, newAreaIndex, currentAreaName = '') => {
    if (!isError) {
      if (currentAreaName) {
        lastSelectedAreaNameRef.current = currentAreaName;
      }
      setSelectedArea(newAreaIndex);
      setMenuName(ADD_AREA);
    }
  };

  const onDelete = (areaName = '', index = null) => {
    lastSelectedAreaNameRef.current = areaName;
    setSelectedArea(index);
    setMenuName(DELETE_ALL_INPUTS_CONFIRMATION);
  };

  const onSave = (currentAreaName) => {
    lastSelectedAreaNameRef.current = currentAreaName;
    setToggled(true);
    setMenuName(LANDING);
  };

  const drawerBodyContent = {
    [LANDING]: <RoomAreasBody
      onClick={(index) => {
        setSelectedArea(index);
        setMenuName(ADD_AREA);
      }}
      onDelete={onDelete}
    />,
    [ADD_AREA]: <SingleAreaCalculatorBody selectedArea={selectedArea} />,
    [DELETE_ALL_INPUTS_CONFIRMATION]: <DeleteConfirmationBody affectedAreaName={lastSelectedAreaNameRef.current} />
  };

  const drawerFooterContent = {
    [LANDING]: <RoomAreasFooter onAddAreaClick={onAddAreaClick} onSaveAndClose={onClose} />,
    [ADD_AREA]: <SingleAreaCalculatorFooter
      onAddAreaClick={onAddAreaClick}
      onSave={onSave}
      selectedArea={selectedArea}
    />,
    [DELETE_ALL_INPUTS_CONFIRMATION]: (
      <DeleteConfirmationFooter
        onCancel={reset}
        onConfirm={reset}
        selectedArea={selectedArea}
      />
    )
  };

  return (
    <>
      {/* TODO: Drawer currently doesn't close when clicking away from it as expected. */}
      <Drawer open={drawerOpen} onClose={onClose} fixed>
        <DrawerHeader
          title={PLAN_YOUR_PROJECT}
          onBack={onBack}
          onClose={onClose}
        />
        <DrawerBody>
          {drawerBodyContent[menuName]}
        </DrawerBody>
        <DrawerFooter>
          <div className="sui-grid sui-gap-y-2">
            <ToasterFooter />
            {drawerFooterContent[menuName]}
          </div>
        </DrawerFooter>
      </Drawer>
    </>
  );
};

CalculatorDrawer.displayName = 'CalculatorDrawer';

CalculatorDrawer.propTypes = {
  drawerOpen: bool.isRequired,
  setDrawerOpen: func.isRequired,
  label: string.isRequired
};

export default CalculatorDrawer;